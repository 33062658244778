import logo from "./logo.svg";
import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import FileUpload from "./components/fileUpload.component";
import ProductsPage from "./components/list.component";
import Header from "./components/header.component";
import { Box } from "@mui/material";
import Login from "./components/login.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLoggedIn } from "./services/auth";
import Dashboard from "./components/dashboard.component";
import Settings from "./components/settings.component";
import ExcelDownload from "./components/excelDownload.component";
const owners = ["SIN", "BRS", "EGS", "EGX", "ES", "EN","STA","CRS", "Automa"];
function App() {
  return (
    <div>
    
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<AuthenticatedApp />} />
          </Routes>
        </Router>
        <ToastContainer position="top-right" style={{ top: "60px" }} />
  
    </div>
  );
}

function AuthenticatedApp() {
  if (!isLoggedIn()) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Header owners={owners} />
      <Box m={2}>
        <Routes>
          <Route path="/" element={<Dashboard/>}/>
          
          {owners.map((owner) => (
            <Route
              key={owner}
              path={`/products/${owner}`}
              element={
                <>
                 {owner === "Automa" ? (
          <ExcelDownload/>
        ) : (
          <FileUpload owner={owner} />
        )}
        <ProductsPage owner={owner} />
                </>
              }
            />
          ))}
          <Route path="/settings" element={<Settings />}/>
        </Routes>
      </Box>
    </>
  );
}
export default App;
