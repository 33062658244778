
import Cookies from "js-cookie";
import cookie from 'cookie';

export const isLoggedIn = (reqCookies = null) => {
    if (!reqCookies) {
      return !!Cookies.get('is_user_logged_in');
    }
    return !!cookie.parse(reqCookies).is_user_logged_in;
  };
export const logIn = (navigate) => {
  Cookies.set("is_user_logged_in", true, { expires: 1, sameSite: "lax" });
  navigate('/');
};

export const logOut = (navigate) => {
  Cookies.remove("is_user_logged_in", { sameSite: "lax" });
  navigate('/login');
};
