import React, { useEffect, useState } from 'react';
import { Switch, FormControlLabel, Button } from '@mui/material';
import apiClient from '../services/api';
import EnableTwoFa from './enable-two-fa.component';

const Settings = () => {
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [showEnableModal, setShowEnableModal] = useState(false);

  useEffect(() => {
    apiClient.get('/api/me').then((result) => {
      setUser(result.data.data);
      setTwoFactorAuth(!!result.data?.data?.has2FA);
    });
  }, []);
  const handleTwoFactorAuthToggle = () => {
    setTwoFactorAuth((prevValue) => !prevValue);
    console.log(twoFactorAuth);
  };

  const handleEnableModalOpen = () => {
    setShowEnableModal(true);
  };

  const handleEnableModalClose = () => {
    setShowEnableModal(false);
  };
  return (
    <div>
      <h2>Settings</h2>
      <FormControlLabel
        control={
          <Switch
            checked={twoFactorAuth}
            onChange={handleTwoFactorAuthToggle}
          />
        }
        label="Two-Factor Authentication"
      />

      {!twoFactorAuth && (
        <Button variant="contained" onClick={handleEnableModalOpen}>
          Enable Two-Factor Authentication
        </Button>
      )}

      <EnableTwoFa
        show={showEnableModal}
        onClose={handleEnableModalClose}
        onSuccess={() => setTwoFactorAuth(true)}
      />
    </div>
  );
};

export default Settings;
