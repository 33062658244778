import React, { useEffect, useState } from "react";
import apiClient from "../services/api";
import { Button, Form, Table } from 'react-bootstrap';



const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [partNumber, setPartNumber] = useState('');
  const [results, setResults] = useState([]);
  useEffect(() => {
    const fetchCurrentUser = async () => {
      // Make an API request to retrieve the currentUser data from the server
      apiClient
        .get(`/api/me`)
        .then((response) => {
          //console.log(response);
          setCurrentUser(response.data.data.name);
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
        });
    };

    // Update the currentUser state with the received data

    fetchCurrentUser();
  }, []);
  function debounce(fn, delay) {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
  const handleSearch = async (e) => {
    //console.log(e.target.value);
    try {
      const response = await apiClient.get(`/api/search?partNumber=${e.target.value}`);
      setResults(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const debouncedHandleSearch =(e)=> {debounce(handleSearch(e), 300);}; // Adjust the delay as per your preference. 300ms is generally a good value.

const handleInputChange = (e) => {
  setPartNumber(e.target.value);
  debouncedHandleSearch(e);
};
  return (
    <div>
      <h2>Welcome, {currentUser}!</h2>
      <p>This is your dashboard.</p>

      {/* Search Functionality */}
      <div className="search-section">
      <Form >
          <Form.Control 
            type="text"
            onChange={handleInputChange}
            placeholder="Search by part number..."
            style={{ width: '300px' }}
          />
        </Form>
        {partNumber && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Part Number</th>
                <th>Qty</th>
                <th>Owner</th>
              </tr>
            </thead>
            <tbody>
              {results.map((product, index) => (
                <tr key={index}>
                  <td>{product.PartNo}</td>
                  <td>{product.Qty}</td>
                  <td>{product.owner}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
