import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import apiClient from '../services/api';

const FileUpload=({ owner }) =>{
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const submitFile = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        apiClient.post(`/api/upload/${owner}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            console.log(response);
            setUploadStatus(response.data.message);
        }).catch(error => {
            console.log(error);
            setUploadStatus("File upload failed.");
        });
        setFile(null);
    setUploadStatus('File uploaded successfully!');
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const fileType = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    
        if (fileType !== 'csv' && fileType !== 'xls' && fileType !== 'xlsx') {
            setUploadStatus("Invalid file format. Please upload a .csv or .xls(x) file.");
            return;
        }
    
        setFile(file);
        setUploadStatus("");
    };
    

    return (
        <Card style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title>Upload File</Card.Title>
                <form onSubmit={submitFile}>
                    <div className="mb-3">
                        <input className="form-control" type="file" onChange={handleFileChange} />
                    </div>
                    <Button variant="primary" type="submit">
                        Upload
                    </Button>
                </form>
                {uploadStatus && <Card.Text style={{ marginTop: '10px' }}>{uploadStatus}</Card.Text>}
            </Card.Body>
        </Card>
    );
    
}

export default FileUpload;
