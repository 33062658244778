import React, { useState, useEffect } from "react";
import apiClient from "../services/api";
import { Button, Card } from 'react-bootstrap';

function ExcelDownload() {
    const [lastModifiedDate, setLastModifiedDate] = useState(null);
    // Fetch the last modified date on component mount
    useEffect(() => {
        const timestamp = new Date().getTime();
apiClient.head(`/api/exports/SYNCREL INVENTORY.xlsx?${timestamp}`)
            .then(response => {
                const dateHeader = response.headers['last-modified'];
                if (dateHeader) {
                    setLastModifiedDate(new Date(dateHeader));
                }
            })
            .catch(error => {
                console.error("There was a problem with the fetch operation:", error.message);
            });
    }, []);

    const handleDownload = () => {
        apiClient.get("/api/exports/SYNCREL INVENTORY.xlsx", { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'SYNCREL INVENTORY.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error("There was a problem with the fetch operation:", error.message);
            });
    };
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; // January is 0
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

    };
    return (
        <Card className="text-left" style={{ width: 'auto' }}>
            <Card.Body>
                <Card.Title>Stock File</Card.Title>
                <Button variant="primary" onClick={handleDownload}>Download Excel</Button>
                {lastModifiedDate && (
                    <Card.Text style={{ marginTop: '15px' }}>
                        Last Upload: {formatDate(lastModifiedDate)}
                    </Card.Text>
                )}
            </Card.Body>
        </Card>
    );
}
  
  export default ExcelDownload;

  
  
  
  
  