import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ExportButton from "./exportProducts.component";
import { AppBar, Toolbar, Button, Typography } from "@mui/material";
import { logOut } from "../services/auth";
import apiClient from "../services/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { Settings, Logout } from '@mui/icons-material';
const owners = ["SIN", "BRS", "EGS", "EGX", "ES", "EN","STA","CRS","Automa"];

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleLogout = () => {
    apiClient.post("/api/logout").then((response) => {
      if (response.status === 204) {
        logOut(navigate);
      }
    });
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            SyncStock
          </Link>
        </Typography>
        {owners.map((owner) => (
          <Button
            color="inherit"
            key={owner}
            component={Link}
            to={`/products/${owner}`}
          >
            {owner}
          </Button>
        ))}
        <ExportButton />
        <div>
        <IconButton color="inherit" onClick={handleMenuOpen}>
        <AccountCircleIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSettingsClick}>
          <Settings /> Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Logout /> Logout
        </MenuItem>
      </Menu>
      </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
