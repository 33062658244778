import React from 'react';
import { Form } from 'react-bootstrap';

const SearchBar = ({ handleInputChange }) => {
  return (
    <Form>
      <Form.Control
        type="text"
        onChange={handleInputChange}
        placeholder="Search by part number..."
        style={{ width: '300px' }}
      />
    </Form>
  );
};

export default SearchBar;