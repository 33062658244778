import axios from "axios";
import Cookies from "js-cookie";

const apiClient = axios.create({
  baseURL: "https://syncapi.syncrel.com",
  //baseURL: "http://localhost:8000",
  
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    
  },
  withCredentials: true
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.log("tzeapa");
      Cookies.remove("is_user_logged_in", { sameSite: "lax" });
      window.location.href = "/login";

      return Promise.reject({status:401,errors:['Unauthorized']});
    }
    if(error.response?.status===422){
      let errors=Object.values(error?.response?.data?.errors || {});
      return Promise.reject({status:422, errorsRaw:errors, errors:errors.reduce(error=>error)});
    }
    console.error(error);
    return Promise.reject(error);
  }
);

export default apiClient;
