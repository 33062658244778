import { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import apiClient from "../services/api";

const ConfirmPass = ({ confirming, setConfirming, onFail, onSuccess }) => {
  const [password, setPassword] = useState("");
  const confirm = () => {
    apiClient
      .post("/user/confirm-password", { password })
      .then(() => {
        onSuccess();
      })
      .catch(({ error, status }) => {
        console.log(error, status);
        onFail();
      });
  };
  return (
    <Modal isOpen={confirming} setIsOpen={setConfirming}>
      <div>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
      </div>
      <div className="flex justify-between pt-10">
        <Button onClick={() => setConfirming(false)}>Cancel</Button>
        <Button className="bg-green-400 text-white" onClick={confirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
export default ConfirmPass;
