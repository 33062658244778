import React from 'react';
import { useNavigate  } from 'react-router-dom';
import apiClient from '../services/api';
import '../styles/login.component.css';
import { logIn } from '../services/auth';
import axios from 'axios';
const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [toHome, setToHome] = React.useState(false);
    const [authError, setAuthError] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setAuthError(false);
        setUnknownError(false);
        apiClient.get('/sanctum/csrf-cookie').then(response => {
          apiClient.post('/api/login', {
              email: email,
              password: password
          }).then(response => {
              if (response.status === 204) {
                  logIn(navigate);
                  setToHome(true);
              }
          }).catch(error => {
              if (error.response && error.response.status === 422) {
                  setAuthError(true);
              } else {
                  setUnknownError(true);
                  console.error(error);
              }
          });
      });
        
    }
    if (toHome === true) {
      navigate('/');
    }
    return (
      <div className="page-background">
          <div className="login-container">
              <div>
                  <h3>Login</h3>
                  <form onSubmit={handleSubmit}>
                      <div className="form-group">
                          <input
                              type="email"
                              name="email"
                              className={"form-control" + (authError || unknownError ? ' is-invalid' : '')}
                              placeholder="Email"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              required
                              style={{ width: '200px' }} 
                          />
                      </div>
                      <div className="form-group">
                          <input
                              type="password"
                              name="password"
                              className={"form-control" + (authError || unknownError ? ' is-invalid' : '')}
                              placeholder="Password"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              required
                              style={{ width: '200px' }} 
                          />
                      </div>
                      {authError ? <div className="alert alert-danger">Credentials not recognised. Please try again.</div> : null}
                      {unknownError ? <div className="alert alert-danger">There was an error submitting your details.</div> : null}
                      <button type="submit" className="btn btn-primary">Login</button>
                  </form>
              </div>
          </div>
      </div>
  );
  
      
};

export default Login;