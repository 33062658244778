import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

function AddProductForm({ onSubmit, successMessage }) {
  const [PartNo, setPartNumber] = useState('');
  const [Mfc, setManufacturer] = useState('');
  const [Qty, setQuantity] = useState('');
  const [Desc, setDescription] = useState('');
  const location = useLocation();
  const currentPageName = location.pathname;
  const owner = currentPageName.split("/").pop();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(owner);
    onSubmit({ PartNo, Mfc, Qty, Desc,owner });
    setPartNumber('');
    setManufacturer('');
    setQuantity('');
    setDescription('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Part Number:
        <input value={PartNo} onChange={(e) => setPartNumber(e.target.value)} />
      </label>
      <label>
        Manufacturer:
        <input value={Mfc} onChange={(e) => setManufacturer(e.target.value)} />
      </label>
      <label>
        Quantity:
        <input value={Qty} onChange={(e) => setQuantity(e.target.value)} />
      </label>
      <label>
        Description:
        <input value={Desc} onChange={(e) => setDescription(e.target.value)} />
      </label>
      <button className="btn btn-outline-primary" type="submit">Add Product</button>
      {successMessage && <p>{successMessage}</p>}
    </form>
    
  );
}

export default AddProductForm;