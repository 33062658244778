import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from "../services/api";

function ExportButton() {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
  setLoading(true);
    apiClient.get('/api/export-products')
    .then(response => {
      for(let server in response.data.status){
        if(response.data.status[server]==='success') toast.success(`Exported successfully to ${server}`, { autoClose: 5000 });
        else toast.error(`Export to ${server} failed`, { autoClose: 5000 });
      }
      
    })
    .catch(error => {
      toast.error('An error occurred: ' + error.message, { autoClose: 5000 });
    }).finally(() => {
      setLoading(false); // Reset loading to false once request is complete
    });
  };

  return (
    <div>
      <Button variant="outline-light" onClick={handleClick} className="me-2" disabled={loading}>
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
            Exporting...
          </>
        ) : (
          'Export Products'
        )}
      </Button>
    </div>
  );
}

export default ExportButton;
