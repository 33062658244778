import React,{useState} from "react";
import { Table } from "react-bootstrap";

const SearchTable = ({ results, selectedResults, setSelectedResults, setEditProduct, setModalIsOpen, setEditedQuantity }) => {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const handleCheckboxChange = (e, result) => {
    if (e.target.checked) {
      setSelectedResults([...selectedResults, result]);
    } else {
      setSelectedResults(selectedResults.filter((r) => r.id !== result.id));
    }
  };
  const handleEditClick = (product) => {
    setEditProduct(product);
    setEditedQuantity(product.Qty.toString());
    setModalIsOpen(true); // Open the modal when the Edit button is clicked
  };
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Select</th>
          <th>Part Number</th>
          <th>Qty</th>
          <th>Owner</th>
        </tr>
      </thead>
      <tbody>
        {results.map((product, index) => (
           <tr
           key={product.PartNo}
           onMouseEnter={() => setHoveredProductId(product.id)}
           onMouseLeave={() => setHoveredProductId(null)}
         >
            <td>
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, product)}
              />
            </td>
            <td>{product.PartNo}</td>
            <td>{product.Qty}{" "}
              {hoveredProductId === product.id && (
                <button onClick={() => handleEditClick(product)}>
                  Edit
                </button>
              )}</td>
            <td>{product.owner}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SearchTable;
