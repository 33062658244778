import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import apiClient from "../services/api";
import { useLocation } from "react-router-dom";
import SearchBar from "./searchBar.component";
import AddProductForm from "./AddProductForm.component";
import ReactModal from "react-modal";
import SearchTable from "./searchTable.component";
function Pagination({ page, setPage, total }) {
  const totalPages = Math.ceil(total / 10);
  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value);
    setPage(newPage);
  };
  return (
    <div className="d-flex align-items-center justify-content-center mt-2">
      <button
        className="btn btn-outline-primary me-2"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        Previous
      </button>
      <p className="mb-0">
        Page{" "}
        <select id="page-select" value={page} onChange={handlePageChange}>
          {Array.from({ length: totalPages }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>{" "}
        of {totalPages}
      </p>
      <button
        className="btn btn-outline-primary ms-2"
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
      >
        Next
      </button>
    </div>
  );
}
function ProductsTable({  products, selectedProducts, setSelectedProducts, setEditProduct, setModalIsOpen, setEditedQuantity }) {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const handleCheckboxChange = (e, product) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts(
        selectedProducts.filter((p) => p.PartNo !== product.PartNo)
      );
    }
  };
  const handleEditClick = (product) => {
    setEditProduct(product);
    setEditedQuantity(product.Qty.toString());
    setModalIsOpen(true); // Open the modal when the Edit button is clicked
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Select</th>
          <th>Part Number</th>
          <th>Manufacturer</th>
          <th>Quantity</th>
          <th>Description</th>
          <th>Added At</th>
          <th>Updated At</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <tr
          key={product.PartNo}
          onMouseEnter={() => setHoveredProductId(product.id)}
          onMouseLeave={() => setHoveredProductId(null)}
        >
            <td>
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, product)}
              />
            </td>
            <td>{product.PartNo}</td>
            <td>{product.Mfc}</td>
            <td>{product.Qty}  {hoveredProductId === product.id && (
                    <button onClick={() => handleEditClick(product)}>
                      Edit
                    </button>
                  )}</td>
            <td>{product.Desc}</td>
            <td>{product.created_at_human}</td>
            <td>{product.updated_at_human}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function ProductsPage({ owner }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSrch, setPageSrch] = useState(1); //page number for search results
  const [total, setTotal] = useState(0);
  const [totalS, setTotalS] = useState(0);
  const location = useLocation();
  const currentPageName = location.pathname;
  const pageIdentifier = currentPageName.split("/").pop();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [partNumber, setPartNumber] = useState("");
  const [results, setResults] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [editProduct, setEditProduct] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState("");
  useEffect(() => {
    const fetchProducts = () => {
      setIsLoading(true);
      apiClient
        .get(`/api/products?owner=${owner}&page=${page}`)
        .then((response) => {
          setProducts(response.data.data);
          setTotal(response.data.total || 1);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchProducts();
  }, [owner, page]);
  useEffect(() => {
    setPage(1); // Reset the page number to 1 when owner changes
    setPageSrch(1); // Reset the page number to 1 when owner changes
    setResults([]);
    setPartNumber("");
  }, [owner]);

  function debounce(fn, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
  const debouncedHandleSearch = debounce(async () => {
    try {
      const response = await apiClient.get(
        `/api/search?partNumber=${partNumber}&pageName=${pageIdentifier}&page=${pageSrch}`
      );
      console.log("API response data: ", response.data.data);
      setResults(Array.isArray(response.data.data) ? response.data.data : []);
      setTotalS(response.data.total || 1);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }, 300);

  // UseEffect to handle page changes
  useEffect(() => {
    if (partNumber) debouncedHandleSearch();
  }, [partNumber, pageSrch]);
  const handleInputChange = (e) => {
    setPartNumber(e.target.value); // Update the partNumber triggering the debounced search
  };
  const removeProducts = async (selectedProducts) => {
    try {
      // Loop through the selected products
      for (let product of selectedProducts) {
        // Send a DELETE request for each product
        const response = await apiClient.delete(`api/product/${product.id}`);
        
        // Log the response data
        console.log(`Deleted product with ID ${product.id}:`, response.data);
      }
      setProducts(products.filter(product => !selectedProducts.includes(product)));
    } catch (error) {
      // Log any errors that occur
      console.error('Error deleting products:', error);
    }
  };
  const handleAddProduct = async (newProduct) => {
    try {
      // Save the new product to the database or perform the desired action
      console.log("Adding new product:", newProduct);
      const response = await apiClient.post("api/product", newProduct);
      setSuccessMessage(response.data.message);
      // Refresh the products list after adding the new product
    } catch (error) {
      console.error("Error adding new product:", error.message);
    }
  };
  const setProductsAndUpdate = async () => {
    try {
      const response = await apiClient.get(`/api/products?owner=${owner}&page=${page}`);
      setProducts(response.data.data);
      setTotal(response.data.total || 1);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  const handleEditQuantity = async () => {
    try {
      const response = await apiClient.put(`api/product/${editProduct.id}`, {
        Qty: editedQuantity,
      });
      console.log(`Updated product quantity for ID ${editProduct.id}:`, response.data);
      await setProductsAndUpdate();
      const updatedResults = results.map((result) =>
      result.id === editProduct.id ? { ...result, Qty: editedQuantity } : result
    );
    
    setResults(updatedResults);
      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter((product) => product.id !== editProduct.id)
      );
      setEditProduct(null);
      setEditedQuantity("");
      setModalIsOpen(false); // Close the modal after updating quantity
    } catch (error) {
      console.error('Error updating product quantity:', error);
    }
  };
  if (isLoading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Search bar */}
        <SearchBar handleInputChange={handleInputChange} />
        {/* Add and remove buttons */}
        <>
          <button
            className="btn btn-outline-primary"
            onClick={() => setModalIsOpen(true)}
          >
            Add Product
          </button>
          <button className="btn btn-outline-primary" onClick={() => removeProducts(selectedProducts)}>
            Remove Selected Products
          </button>
        </>
      </div>

      {/* AddProductForm in a modal */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <AddProductForm
          onSubmit={handleAddProduct}
          successMessage={successMessage}
        />
        <button
          className="btn btn-outline-primary"
          onClick={() => setModalIsOpen(false)}
        >
          Close
        </button>
      </ReactModal>

      {partNumber === "" && (
        <Pagination page={page} setPage={setPage} total={total} />
      )}
      {partNumber === "" && (
        <ProductsTable
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          setEditProduct={setEditProduct}
          setModalIsOpen={setModalIsOpen}
          setEditedQuantity={setEditedQuantity}
        />
      )}
       {editProduct && (
         <ReactModal
         isOpen={modalIsOpen}
         onRequestClose={() => {
           setEditProduct(null);
           setModalIsOpen(false);
         }}
       >
          <p>Edit quantity for {editProduct.PartNo}:</p>
          <input
            type="number"
            value={editedQuantity}
            onChange={(e) => setEditedQuantity(e.target.value)}
          />
          <button onClick={handleEditQuantity}>Save</button>
          <button onClick={() => {
            setEditProduct(null);
            setModalIsOpen(false);
          }}>Cancel</button>
        </ReactModal>)}
      {partNumber && (
        <>
          <Pagination page={pageSrch} setPage={setPageSrch} total={totalS} />
          <SearchTable
            results={results}
            selectedResults={selectedProducts}
            setSelectedResults={setSelectedProducts}
            setEditProduct={setEditProduct}
            setModalIsOpen={setModalIsOpen}
            setEditedQuantity={setEditedQuantity}
          />
        </>
      )}
    </>
  );
}

export default ProductsPage;
