import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import apiClient from "../services/api";
import ConfirmPass from "./confirm-pass.component";
const EnableTwoFa = ({ show, onClose, onSuccess }) => {
  const [confirming, setConfirming] = useState(false);
  const [twoFAEnabled, setTwoFAEnabled] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const enable = () => {
    apiClient
      .post("/user/two-factor-authentication")
      .then(() => {
        //get qr code
        apiClient.get("/user/two-factor-qr-code").then(({ data }) => {
          setQrCode(data.svg);
        });
        //get recovery codes
        apiClient.get("/user/two-factor-recovery-codes").then(({ data }) => {
          setRecoveryCodes(data);
        });

        setConfirming(false);
        setTwoFAEnabled(true);
      })
      .catch(({ error, status }) => {
        if (status === 423) {
          setConfirming(true);
        }
      });
  };
  return (
    <div>
        {confirming ? (
        <ConfirmPass
        confirming={true}
        setConfirming={setConfirming}
        onSuccess={enable}
        onFail={() => alert("Failed to confirm password")}
      />
      ) : null}

      <Modal show={show} onHide={onClose}>
        <Modal.Body>
          {qrCode && <span dangerouslySetInnerHTML={{ __html: qrCode }} />}
          <div className="mt-5">
            <h3 className="font-bold text-lg mb-2">Recovery Codes</h3>
            <div className="gap-2">
              {recoveryCodes.map((recoveryCode) => (
                <div key={recoveryCode}>{recoveryCode}</div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EnableTwoFa;
